import React, { useContext, useEffect, useState } from "react";
import "./carts.scss";
import { Link } from "react-router-dom";
import { CustomContext } from "../../components/contexts/ProductUsersContext";
import { EmptyCart } from "../emptyCart/EmptyCart";
import { BASE_URL } from "../../utils/Constants";

function Carts() {
  const { order, removeBucket, incQuantity, decQuantity, setProducts } =
    useContext(CustomContext);

  const [cartItems, setCartItems] = useState(() => {
    // Загружаем данные из localStorage при первой загрузке компонента
    const storedItems = localStorage.getItem("cartItems");
    return storedItems ? JSON.parse(storedItems) : [];
  });

  const totalPrice = cartItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  useEffect(() => {
    // Обновляем корзину в Context (глобальное состояние)
    setProducts(cartItems);
  }, [cartItems, setProducts]);

  useEffect(() => {
    // Синхронизируем изменения корзины с localStorage
    if (cartItems.length > 0) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    } else {
      localStorage.removeItem("cartItems"); // Удаляем данные из localStorage, если корзина пуста
    }
  }, [cartItems]);

  const handleRemoveItem = (id) => {
    const updatedCart = cartItems.filter((item) => item.id !== id);
    setCartItems(updatedCart); // Обновляем локальное состояние
    removeBucket(id); // Удаляем из глобального состояния
  };

  return cartItems.length === 0 ? (
    <EmptyCart />
  ) : (
    <>
      <div className="page_title">
        <h1>Корзина</h1>
      </div>
      <div className="basket">
        <div className="mainBox">
          {cartItems.map((item) => (
            <div className="carts" key={item.id}>
              <div className="imageBox">
                <img src={BASE_URL + item.picture} alt={item.name} />
              </div>
              <div className="title">
                <h3>{item.name}</h3>
              </div>
              <div className="priceBox">
                {item.price === item.oldPrice ? (
                  <span className="action">{item.oldPrice} р</span>
                ) : (
                  <>
                    <span className="price">{item.oldPrice} р</span>{" "}
                    <span className="action">{item.price} р</span>{" "}
                  </>
                )}
              </div>
              <div className="amounts">
                <button
                  className="btnMinus"
                  onClick={() =>
                    item.quantity <= 1
                      ? handleRemoveItem(item.id)
                      : decQuantity(item.id)
                  }
                >
                  -
                </button>
                <span className="amount">{item.quantity}</span>
                <button
                  className="btnPlus"
                  onClick={() => incQuantity(item.id)}
                >
                  +
                </button>
              </div>
              <div className="buttons">
                <button
                  className="delete"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  Удалить
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="checkout">
          <div className="titleSum">
            <div>Итого:</div>
            <div>{totalPrice} руб.</div>
          </div>
          <div className="buttonBox">
            <Link to="/ordering">
              <button className="buy">Оформить заказ</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carts;
